<template>
	<div class="app-container">
		<el-form inline size="small" label-width="100px">
			<el-form-item label="售后单号：">
				<el-input class="input-width" v-model="filter.afterSaleCode" @keyup.enter.native="search" placeholder="请输入售后单号"></el-input>
			</el-form-item>
			<el-form-item label="订单编号：">
				<el-input class="input-width" v-model="filter.orderNo" @keyup.enter.native="search" placeholder="请输入订单编号"></el-input>
			</el-form-item>
			<el-form-item label="售后类型：">
				<el-select class="option-item" v-model="filter.type">
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="item in refundStatus" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="售后状态：">
				<el-select class="input-width" v-model="filter.workStatus" placeholder="请选择售后状态">
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="申请时间：">
				<el-date-picker style="width: 330px" v-model="applyTime" type="datetimerange" range-separator="～" start-placeholder="开始日期" end-placeholder="结束日期"
						value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
			</el-form-item>
			<el-form-item label="付款时间：">
				<el-date-picker style="width: 330px" v-model="payTime" type="datetimerange" range-separator="～" start-placeholder="开始日期" end-placeholder="结束日期"
								value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
			</el-form-item>
			<el-form-item label="发货时间：">
				<el-date-picker style="width: 330px" v-model="deliverTime" type="datetimerange" range-separator="～" start-placeholder="开始日期" end-placeholder="结束日期"
								value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="search">查询</el-button>
				<button-permissions style="margin-left: 10px" :datas="'refundAfterOrderExport'">
					<el-button type="primary" @click="groupExport" :loading='exportLoading'>导出</el-button>
				</button-permissions>
			</el-form-item>
		</el-form>
		<!-- tab切换 -->
		<el-tabs v-model="filter.searchType" @tab-click="search">
			<el-tab-pane v-for="item in listStateList" :key="item.id" :label="item.value + (item.num?'('+item.num+')':'')" :name="item.id+''"></el-tab-pane>
		</el-tabs>
		<el-table :data="tableData" v-loading="loading" border>
			<el-table-column prop="payTime" label="付款时间"></el-table-column>
			<el-table-column prop="createTime" label="申请时间" ></el-table-column>
			<el-table-column prop="afterSaleCode" label="售后单号"></el-table-column>
			<el-table-column prop="orderNo" label="订单号"></el-table-column>
			<el-table-column label="售后状态">
				<template slot-scope="scope">
					<div>{{typeOptions[scope.row.status-1].label}}</div>
				</template>
			</el-table-column>
			<el-table-column prop="createTime" label="售后类型">
				<template slot-scope="scope">
					<div>{{scope.row.type === 1 ? '退款': scope.row.type === 2 ?  '退货退款':'换货'}}</div>
				</template>
			</el-table-column>
			<el-table-column label="操作" width="170">
				<template slot-scope="scope">
					<div style="display: flex;align-items: center;">
						<button-permissions :datas="'refundAfterOrderToDo'">
							<div class="table-button" style="width: 70px;" @click="handleDetailShow(scope.row)">{{(scope.row.status == 7 || scope.row.status == 8)?'查看详情':'去处理'}}</div>
						</button-permissions>
						<button-permissions :datas="'refundAfterOrderBack'" v-if="scope.row.status == 8">
							<div v-if="!scope.row.isRollback" class="table-button" @click="handleAfterBack(scope.row)">回滚</div>
							<div v-else class="table-button" style="color: #FF4D51;">已回滚</div>
						</button-permissions>
					</div>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination style="text-align: right; padding: 15px" :current-page="filter.pageNo" :page-size="filter.pageSize"
					   :total="filter.total" :page-sizes="filter.sizes" @size-change="pageSizeChange"
					   @current-change="currentChange" background layout="total, sizes, prev, pager, next, jumper"></el-pagination>
	</div>
</template>

<script>
    import apiList from '@/api/other'
    import buttonPermissions from '@/components/buttonPermissions';
    import {afterSaleBack} from '@/api/wyUsedInterface'

    export default {
    name:'refundAfterOrder',
	components: {
		buttonPermissions
	},
	data () {
		return {
			applyTime: '',  //时间
			payTime: '',  //支付时间
			deliverTime: '',  //发货时间
			filter: {
				afterSaleCode: '', //售后单号
				orderNo: '',  	 //订单编号
				workStatus: '',  //售后状态
				searchType: '', //订单类型
				type: null,    //退款状态: 0:审核中 1:退款成功 2：退款失败
				total: 0,
				pageNo: 1,
				pageSize: 20,
			},
            loading: false,
            exportLoading:false,    //导出按钮加载状态
			listStateList: [
				{
					id: 0,
					value: '全部订单',
					num: 0
				},
				{
					id: 1,
					value: '待处理',
					num: 0,
				},
				{
					id: 2,
					value: '已完成',
					num: 0,
				},
			],
			typeOptions: [{
				value: 1,
				label: '待客服审单'
			}, {
				value: 2,
				label: '待客服审核'
			}, {
				value: 3,
				label: '待下发售后地址'
			}, {
				value: 4,
				label: '待买家发货'
			}, {
				value: 5,
				label: '待商家收货'
			}, {
				value: 6,
				label: '待打款'
			}, {
				value: 7,
				label: '售后成功'
			}, {
				value: 8,
				label: '售后关闭'
			}, {
				value: 9,
				label: '待商家发货'
			}],
			refundStatus: [{
				value: 1,
				label: '退款'
			}, {
				value: 2,
				label: '退货退款'
			}, {
				value: 3,
				label: '换货'
			}],
			tableData: [],
		}
	},
	async beforeMount () {
		this.search();
	},
	methods: {
		search() {
			this.filter.pageNo = 1;
			this.getList();
		},
		// 翻页
		currentChange(val) {
			this.filter.pageNo = val;
			this.getList();
		},
		// 切换显示条数
		pageSizeChange(val) {
			this.filter.pageSize = val;
			this.getList();
		},
		// 获取列表数据
		async getList () {
			this.loading = true;
			try {
				let data = {
					afterSaleCode: this.filter.afterSaleCode, //售后单号
					orderNo: this.filter.orderNo,	 //订单编号
					workStatus: this.filter.workStatus,//售后状态
					searchType:this.filter.searchType, //订单类型
					type: this.filter.type,    //退款状态: 0:审核中 1:退款成功 2：退款失败
					name: this.filter.name,
					status: this.filter.status,
					pageNo: this.filter.pageNo,
					pageSize: this.filter.pageSize, // 取的数据
				};
				if (this.applyTime) {
					data.applyStartTime = this.applyTime[0];
					data.applyEndTime = this.applyTime[1];
                }
                if (this.payTime) {
					data.payStartTime = this.payTime[0];
					data.payEndTime = this.payTime[1];
				}
                if (this.deliverTime) {
					data.deliverBigTime = this.deliverTime[0];
					data.deliverEndTime = this.deliverTime[1];
				}
				let result = await apiList.afterSaleList(data);
                //获取各个状态的数量
                let result1 = await apiList.afterSaleCount2(data);
                if (result1.success) {
                    this.listStateList[0].num = result1.data.allNums;
                    this.listStateList[1].num = result1.data.waitDisposeNums;
                    this.listStateList[2].num = result1.data.finishedNums;
                }
				this.tableData = result.data.records;
				this.filter.total = result.data.total;
			} catch (error) {
				this.loading = false
			} finally {
				this.loading = false;
			}
		},
		// 导出
		async groupExport () {
            this.exportLoading = true;
			let data = {
				afterSaleCode: this.filter.afterSaleCode, //售后单号
				orderNo: this.filter.orderNo,	 //订单编号
				workStatus: this.filter.workStatus,//售后状态
				searchType:this.filter.searchType, //订单类型
				type: this.filter.type,    //退款状态: 0:审核中 1:退款成功 2：退款失败
				name: this.filter.name,
				status: this.filter.status,
				pageNo: this.filter.pageNo,
				pageSize: this.filter.pageSize, // 取的数据
				isDownload: true, // 取的数据
			};
			if (this.applyTime) {
				data.applyStartTime = this.applyTime[0];
				data.applyEndTime = this.applyTime[1];
			}
			if (this.payTime) {
				data.payStartTime = this.payTime[0];
				data.payEndTime = this.payTime[1];
			}
			if (this.deliverTime) {
				data.deliverBigTime = this.deliverTime[0];
				data.deliverEndTime = this.deliverTime[1];
			}
			await apiList.afterSaleListExport(data);
            this.$message({ type: 'success', message: '导出成功，请稍后至下载中心查看!' });
            this.exportLoading = false;
		},
		//跳转至详情
		handleDetailShow (row) {
            let params = { id: row.afterSaleId };
			if (row.type === 3) {
                this.publicToDetails(params,'/order/refundAfterSale/exChangeRefundDetails',this);
			} else {
                this.publicToDetails(params,'/order/refundAfterSale/refundDetails',this);
            }
		},
		async handleAfterBack(row) {
			let { success, alertMsg } = await afterSaleBack({
				afterSaleId: row.afterSaleId
			});
			if (success) {
				this.getList();
				this.$message({type: 'success', message: '回滚成功'});
			} else {
				this.$message({type: 'error', message: alertMsg || '未知错误'});
			}
		}
	}
}
</script>

<style lang="less" scoped>
.app-container {
	background-color: #fff;
}

.input-item {
	margin-left: 5px;
}

.table-button {
	color: #409eff;
	display: inline-block;
	// margin-right: 20px;
	cursor: pointer;
}

/deep/.el-table__fixed-body-wrapper {
	background: #ffffff;
}
/deep/.el-table__fixed-header-wrapper {
	background: #f8f8f9;
}
</style>
